import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoles } from '../api-authorization/api-authorization.constants';
import { AuthorizeService } from '../api-authorization/authorize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private authorizeService: AuthorizeService,
    private router: Router) { }

  ngOnInit() {
    this.authorizeService.isAccessTokenExpired().subscribe(expired => {
      if (expired) {
        this.router.navigate(["/authentication/logout"], { state: { local: true } });
        return;
      }
    });
  }

}
